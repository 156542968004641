<template>
  <div class="min-vh-100 tabs shadow-sm" v-if="hideHeader === false">
    <input
      type="radio"
      id="tab1"
      name="tab-control"
      checked
      @click="title = 'ข้อมูลสมาชิก'"
    />
    <input
      type="radio"
      id="tab2"
      name="tab-control"
      v-if="showvoucher === true"
      @click="title = 'E-Voucher'"
    />
    <input
      type="radio"
      id="tab3"
      name="tab-control"
      v-if="showrewardcard === true"
      @click="title = 'บัตรสะสมแต้ม'"
    />
    <ul class="ulbar font-weight-normal">
      <li>
        <label for="tab1" role="button">
          <img
            class="img-fluid"
            width="25%"
            src="https://cdn-icons-png.flaticon.com/512/1067/1067264.png"
            alt=""
          />
          <h6 class="small text-dark mt-1 font-weight-normal">
            ข้อมูลสมาชิก
          </h6>
        </label>
      </li>
      <li v-if="showvoucher === true">
        <label for="tab2" role="button">
          <img
            class="img-fluid"
            width="25%"
            src="https://cdn-icons-png.flaticon.com/512/897/897319.png"
            alt=""
          />
          <h6 class="small text-dark mt-1 font-weight-normal">
            E-Voucher
          </h6>
        </label>
      </li>
      <li v-if="showrewardcard === true">
        <label for="tab3" role="button">
          <img
            class="img-fluid"
            width="25%"
            src="https://cdn-icons-png.flaticon.com/512/4108/4108214.png"
            alt=""
          />
          <h6 class="small text-dark mt-1 font-weight-normal">
            บัตรสะสมแต้ม
          </h6>
        </label>
      </li>
    </ul>
    <div class="slider"><div class="indicator"></div></div>

    <div class="content mt-2">
      <section>
        <member-card></member-card>
      </section>
      <section v-if="showvoucher === true">
        <voucher></voucher>
      </section>
      <section v-if="showrewardcard === true">
        <reward-card></reward-card>
      </section>
    </div>
  </div>

  <div
    class="min-vh-100"
    style="background-color: #ffffff;"
    v-else-if="hideHeader === true"
  >
    <CCardBody>
      <member-card class="mt-4"></member-card>
    </CCardBody>
  </div>
</template>

<script>
import MemberCard from '../member/MemberCard'
import RewardCard from '../../reward/card/member/Card'
import Voucher from '../../reward/voucher/member/VoucherList'
import crm from '@/services/crm'
import '@/util/tabs.css'

export default {
  components: {
    MemberCard,
    Voucher,
    RewardCard,
  },
  data() {
    return {
      member: '',
      showrewardcard: '',
      showvoucher: '',
      voucher: '',
      rewardcard: '',
      isSuspendRewardCard: '',
      hideHeader: '',
      title: 'ข้อมูลสมาชิก',
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
  created() {
    document.title = 'ข้อมูลสมาชิก'
    this.getShowMenuBar()
  },
  methods: {
    getShowMenuBar() {
      const uid = this.uid
      crm
        .get('/api/v1.0/' + uid + '/shop/getmenubar')
        .then((response) => {
          let documents = response.data.data
          this.showrewardcard = documents.rewardcard
          this.showvoucher = documents.voucher

          if (this.showrewardcard === false && this.showvoucher === false) {
            this.hideHeader = true
          } else {
            this.hideHeader = false
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  watch: {
    title() {
      document.title = this.title
    },
  },
}
</script>
