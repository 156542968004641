<template>
  <CRow class="text-dark">
    <CCol md="12">
      <CAlert
        class="p-2"
        border-color="danger"
        color="danger"
        v-if="alertMessage"
      >
        <span style="font-size:12px">
          <i class="fas fa-exclamation-circle"></i>
          {{ alertMessage }}
        </span>
      </CAlert>
      <CCarousel indicators animate>
        <CCarouselItem v-for="i in cardTotal" :key="i">
          <CCard border-color="greyborder" style="border-radius:5px">
            <CCardBody class="p-0" style="border-radius:5px; background-color: rgb(151, 142, 133)">
              <div style="height: 65px;background-color: rgb(32, 32, 32);border-top-right-radius:5px;border-top-left-radius:5px;">
                <table style="height: 100%;">
                  <tr>
                    <td>
                      <img :src="logo"
                      width="65px"
                      class="img-fluid p-2 rounded-circle"
                      @error="noImgUrl" v-if="logo !== ''"/>
                      
                      <img v-else src="../../../../../public/silom-pos.png" 
                      width="65px" 
                      class="img-fluid p-2 rounded-circle"
                      @error="noImgUrl" />
                    </td>
                    <td>
                      <h5 class="text-white">{{ shopName }}</h5>
                      <div class="font-weight-normal text-center text-white" style="font-size: 12px;"
                        v-if="documents !== null">
                        สะสมครบ {{ pointCard }} {{ unitName }} แลกรับฟรี
                        {{ freeCard }}
                        {{ unitName }}
                      </div>
                    </td>
                  </tr>
                </table>                
              </div>
              <br/>
              <div v-if="documents === null">
                <br />
                <p class="text-center font-weight-normal">
                  ร้านค้าไม่ได้ร่วมรายการสะสมแต้ม
                </p>
              </div>
              <p hidden>{{ computePoint }}</p>
              <div class="text-center">
                <div v-if="rewardPoint < pointCard * i">
                  <span hidden>
                    {{ (calculate = pointCard * i - rewardPoint) }}
                  </span>
                  <p v-for="mypoint in pointCard - calculate"
                    :key="mypoint"
                    class="circle"
                    :style="style">
                    &nbsp;
                  </p>
                  <span hidden>{{ (diff = pointCard - calculate) }}</span>
                  <p v-for="allpoint in calculate"
                    :key="allpoint + diff"
                    class="circle text-white">
                    <span v-if="allpoint + diff === pointCard">GOAL</span>
                    <span v-else>{{ allpoint + diff }}</span>
                  </p>
                </div>
                <div v-else @click="handlePopup">
                  <p
                    v-for="mypoint in pointCard"
                    :key="mypoint"
                    class="circle text-white"
                    :style="style">
                    <span v-if="mypoint !== pointCard">&nbsp;</span>
                    <span class="badge1" data-badge="แลกรับ" v-else></span>
                  </p>
                </div>
              </div>
              <br/>
            </CCardBody>
          </CCard>
        </CCarouselItem>
      </CCarousel>

      <hr />
      <p class="font-weight-normal text-dark">หมายเหตุ</p>
      <p style="font-size:12px" class="text-description">
        • ลูกค้าจะได้รับแต้มจากการซื้อสินค้าที่ร่วมรายการภายในร้าน <br />
        • ไม่สามารถใช้แทนเงินสดได้ <br />
        • ไม่สามารถใช้ร่วมกับบัตรแลกของรางวัล ส่วนลด หรืออื่นๆ <br />
        • บัตรสะสมแต้มที่มีสถานะ "ใช้แล้ว" จะไม่สามารถใช้ได้อีก <br />
        • สามารถกดใช้สิทธิ์ได้โดยการกดบัตรสะสมแต้ม และยืนยันการใช้สิทธิ์
      </p>

      <history v-bind:rewardcardObjectId="rewardcardObjectId"></history>
    </CCol>
    <CModal
      :show.sync="popupModal"
      centered
      :footer="footer"
      title="ยืนยันการแลกสินค้า">
      <h5 class="text-center">
        สะสมครบ {{ pointCard }} {{ unitName }} 
        แลกรับฟรี {{ freeCard }} {{ unitName }}
      </h5>
      <div class="text-description text-center" style="font-size: 12px;">
        ยืนยันการใช้สิทธิ์ ใช่หรือไม่?
      </div>
      <div class="text-center text-description" style="font-size:12px">
          *การแลกแต้มจะไม่สามารถยกเลิกหรือขอคืนได้
      </div>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="5" class="pr-1">
            <CButton
              block
              color="success"
              v-on:click="redeemClick"
              :disabled="loadingButton">
              <CSpinner v-if="loadingButton" class="mb-1 mr-1" color="white" size="sm" />ใช่
            </CButton>
          </CCol>
          <CCol col="5" class="pl-1">
            <CButton block color="danger" @click="popupModal = false">
              ไม่ใช่
            </CButton>
          </CCol>
        </CRow>
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/poscrm'
import crm from '@/services/crm'
import util from '@/util/asymmetric'
import liff from '@line/liff'
import History from './History'

export default {
  components: {
    History,
  },
  data() {
    return {
      rewardcardObjectId: '',
      pointCard: 0,
      freeCard: 0,
      member: '',
      memberObjectId: '',
      memberName: '',
      redeemPoint: '',
      rewardPoint: 0,
      unitName: '',
      popupModal: false,
      footer: '',
      documents: '',
      profile: JSON.parse(localStorage.getItem('profile')),
      alertMessage: null,
      loadingButton: false,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    userId() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_LINE_USER_ID
      } else {
        if (this.profile) {
          return this.profile.userId || liff.getContext().userId
        } else {
          alert('มีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
          liff.closeWindow()
        }
      }
    },
    uid() {
      return this.$route.query.uid
    },
    shop() {
      return this.shopLineOA[0]
    },
    shopName() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.shopName
      }
    },
    logo() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.remoteImagePath
      }
    },
    cardTotal() {
      if (this.rewardPoint !== 0 && this.pointCard !== 0) {
        return Math.ceil(this.rewardPoint / this.pointCard)
      } else {
        return 1
      }
    },
    computePoint() {
      let cal = 0
      if (this.pointCard !== 0) {
        if (this.rewardPoint > this.pointCard) {
          cal = this.rewardPoint - this.pointCard
        } else {
          cal = this.pointCard - this.rewardPoint
        }
      }
      return cal
    },
    style() {
      let stamp = ''
      if (this.logo !== '') {
        stamp = {
          backgroundImage: `url('${this.logo}'), url('https://cdn-icons-png.flaticon.com/512/3334/3334338.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      } else {
        stamp = {
          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0D3aSjZZJPWSoGAOz-Knv8xKupKxBd5eqdQ&usqp=CAU')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }
      return stamp
    },
  },
  created() {
    this.getUser()
    this.getRewardCardByUID()
  },
  methods: {
    noImgUrl(event) {
      event.target.src =
        'https://cdn-icons-png.flaticon.com/512/1586/1586085.png'
    },
    handlePopup() {
      this.popupModal = true
    },
    async getRewardCardByUID() {
      const uid = this.uid
      try {
        const response = await crm.get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        this.documents = response.data.data.documents
        if (this.documents !== null && this.documents !== undefined) {
          this.rewardcardObjectId = this.documents.objectId
          this.pointCard = this.documents.point
          this.freeCard = this.documents.free
          this.unitName = this.documents.unit.name
          this.redeemPoint = this.pointCard
          this.alertMessage = this.documents.alertMessage
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getUser() {
      const uid = this.uid
      const userId = this.userId

      try {
        const response = await pos({method: 'get', url: '/api/v1.0/member/' + uid + '/getbyuserId/' + userId,})
        const data = response.data || {}
        if (data.error.code === 0) {
          this.member = data.data.documents
          if (this.member !== null) {
            let rewardPoint = this.member.rewardPoint || 0
            if (rewardPoint <= 0) {
              rewardPoint = 0
            }
            this.rewardPoint = rewardPoint
            this.memberObjectId = this.member.objectId
            this.memberName = this.member.name
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    redeemClick() {
      this.loadingButton = true
      const uid = this.uid
      const memberObjectId = this.memberObjectId
      const userId = this.userId
      const rewardcardObjectId = this.rewardcardObjectId
      const member = {
        objectId: this.memberObjectId,
        name: this.memberName,
      }

      let token = util.asymmetric()

      let redeemdata = {
        rewardcardObjectId: rewardcardObjectId,
        redeemPoint: this.redeemPoint,
        userId: userId,
        member: member,
        headers: {
          'Content-Type': 'application/json',
          token: token,
          app: 'crm-app',
        },
      }

      crm
        .post(
          '/api/v1.0/' + uid + '/rewardcard/' + memberObjectId + '/redeem',
          redeemdata
        )
        .then(async(res) => {
          let responsedata = res.data.data
          if (typeof responsedata === 'string' || responsedata === undefined) {
            this.popupModal = false
            //this.redeemAlert = responsedata
          } else if (responsedata.objectId !== undefined) {
            let redemptionObjectId = responsedata.objectId
            //เมจะสร้างหน้า redemption นี้มาใหม่ไม่ให้ซ้ำกับตัวคูปอง เพราะฉะนั้นต้องเปลี่ยนรีไดเรคนี้ด้วย
            // window.location.href =
            //   process.env.VUE_APP_WEB_URL +
            //   '/rewardcard/redemption/' +
            //   redemptionObjectId

            //  window.location.replace(
            //   '/rewardcard/redemption/' +
            //   redemptionObjectId)
            await this.getUser()
            this.rewardcardObjectId = ''
            await this.getRewardCardByUID()
            window.location.href = '/rewardcard/redemption/' + redemptionObjectId

            this.popupModal = false
            this.loadingButton = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style>
@media screen {
  .circle {
    background: transparent;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 50%;
    border: 1px dashed white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .modal-confirm {
    width: 70%;
    margin: auto;
    border-radius: 15%;
  }
  .badge1 {
   position:relative;
}
.badge1[data-badge]:after {
   content:attr(data-badge);
   position:absolute;
   top:-30px;
   right:-50px;
   font-size:.7em;
   background:green;
   color:white;
   height:18px;
   width: 50px;
   text-align:center;
   line-height:18px;
   border-radius:5px;
   padding-left: 4px;
   padding-right: 4px;
   box-shadow:0 0 1px #ffffff;
}
}
</style>
