<template>
  <CRow class="d-flex justify-content-center">
    <CCol>
      <CCard
        color="white"
        text-color="black"
        class="p-0"
        border-color="greyborder"
        style="border-radius:15px"
      >
        
        <!-- Card profile -->
        <CCardBody v-if="member != null">
          <CJumbotron
            style="background-image: url(/img/card.jpg);border-radius: 15px;background-size: cover;"
          >
            <table style="width:100%">
              <tr>
                <td style="width:25%">
                  <img
                    alt="image profile"
                    class="rounded-circle img-fluid"
                    :src="pictureUrl"
                    onerror="this.onerror=null; this.src='https://cdn-icons-png.flaticon.com/512/4322/4322991.png';"
                  />
                </td>
                <td class="pl-2" valign="top">
                  <h2 class="text-dark font-weight-normal">
                    {{ displayName }}
                  </h2>
                  <small class="text-muted">
                    {{ phone }}
                  </small>
                </td>
                <!-- <td
                  valign="top"
                  class="p-1 text-dark"
                  v-if="qrcode !== ''"
                  style="width:5%"
                >
                  <i class="fas fa-qrcode" @click="qrModal = true"></i>
                </td> -->
                <td valign="top" class="p-1 text-dark" style="width:5%">
                  <i class="far fa-edit" @click="editModal = true"></i>
                </td>
              </tr>
            </table>

            <h6 class="text-muted mt-5 mb-0">
              <i class="far fa-star"></i>
              <strong> {{ showYourPoint(yourpoint) }} </strong> คะแนน
            </h6>
          </CJumbotron>

          <!-- User Information -->
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col-2">
                  <i class="fas fa-user-astronaut fa-lg text-dark"></i>
                </div>
                <div class="col">
                    <div class="h6 text-dark">
                      {{ name }}
                    </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-2">
                  <i class="fas fa-mobile-alt fa-lg text-dark"></i>
                </div>
                <div class="col">
                  <div class="h6 text-dark font-weight-light">
                    {{ phone }}
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-2">
                  <i class="fas fa-envelope-open-text fa-lg text-dark"></i>
                </div>
                <div class="col">
                  <div class="h6 text-dark">
                    {{ email }}
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-2">
                  <i class="fas fa-birthday-cake fa-lg text-dark"></i>
                </div>
                <div class="col">
                  <div class="h6 text-dark">
                    {{ showDateOfBirth(dateOfBirth) }}
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="address !== ''">
              <div class="row">
                <div class="col-2">
                  <i class="fas fa-map-marked-alt fa-lg text-dark"></i>
                </div>
                <div class="col">
                  <div class="h6 text-dark">
                    {{ address }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <CCard v-if="qrcode !== ''" class="p-0">
            <CCardBody color="white" class="p-0">
              <div class="d-flex justify-content-center">
                <vue-qrcode
                class="img-fluid"
                :value= "generatedMemberQRCode()"
                style="width:150px;"
              />
              </div>
              <h6 class="text-dark text-center">
                รหัสสมาชิก {{ qrcode }}
              </h6>
            </CCardBody>
          </CCard>
        </CCardBody>
        <div v-else>
          <div class="lds-ripple">
            <div></div>
          </div>
          <div class="row">
            <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
          </div>
        </div>
      </CCard>
    </CCol>

    <!-- Edit Modal -->
    <CModal
      :show.sync="editModal"
      centered
      :footer="footer"
      add-content-classes="modal-edited"
      class="text-dark"
      :closeOnBackdrop="close"
    > 
    <CRow class="justify-content-center">
      <div class="h4">แก้ไขข้อมูล</div>
    </CRow>
      <ul class="list-group list-group-flush">
        <li class="list-group-item m-0">
          <div class="row">
            <div class="col-2 mt-1 text-dark">
              <i class="fas fa-user-astronaut fa-lg"></i>
            </div>
            <div class="col">
              <input
                type="text"
                v-model="name"
                placeholder="ชื่อ-สกุล"
                required='true'
                class="form-control form-control-md text-dark"
              />
            </div>
            <b class="text-danger">*</b>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-2 mt-1 text-dark">
              <i class="fas fa-mobile-alt fa-lg"></i>
            </div>
            <div class="col">
              <input
                type="tel"
                placeholder="เบอร์โทรฯ"
                v-model="phone"
                class="form-control form-control-md text-dark"
                required='true'
              />
            </div>
            <b class="text-danger">*</b>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-2 mt-1 text-dark">
              <i class="fas fa-envelope-open-text fa-lg"></i>
            </div>
            <div class="col">
              <input
                type="text"
                placeholder="อีเมล"
                v-model="email"
                class="form-control form-control-md text-dark"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-2 mt-1">
              <i class="fas fa-birthday-cake fa-lg text-dark"></i>
            </div>
            <div class="col">
              <date-picker
                v-model="dateOfBirth"
                type="date"
                input-class="form-control form-control-md"
                style="width:100%;"
                format="DD/MM/YYYY"
                :editable=false
                :disabled-date="disabledRange"
                placeholder="วัน/เดือน/ปี เกิด"
              >
              </date-picker>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-2 mt-1">
              <i class="fas fa-map-marked-alt fa-lg text-dark"></i>
            </div>
            <div class="col">
              <textarea
                placeholder="ที่อยู่"
                type="text"
                v-model="address"
                class="form-control form-control-md text-dark"
              />
            </div>
          </div>
        </li>
      </ul>
      <template #header-wrapper>
        <div></div>
      </template>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <small
            v-if="validateAlert !== ''"
            class="text-danger"
            style="display: inline-block;margin-bottom: 10px;"
          >
            <strong>{{ validateAlert }}</strong>
          </small>
        </CRow>

        <CRow class="justify-content-center mb-4">
          <CCol col="4" class="pr-1">
            <CButton color="success" block @click="updateMember()" size="sm">
              บันทึก
            </CButton>
          </CCol>
          <CCol col="4" class="pl-1">
            <CButton block color="dark" @click="cancel()" size="sm">
              ยกเลิก
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>

    <!-- <CModal
      :show.sync="qrModal"
      centered
      :footer="footer"
      add-content-classes="modal-confirm"
    >
      <div class="text-center m-0">
        <vue-qrcode class="img-fluid" :value="qrcode" style="width:250px" />
      </div>
      <template #header-wrapper>
        <div></div>
      </template>
      <template #footer-wrapper>
        <CRow class="justify-content-center m-0"> </CRow>
      </template>
    </CModal> -->
  </CRow>
</template>

<script>
import pos from '@/services/poscrm'
import moment from 'moment'
import liff from '@line/liff'
import VueQrcode from 'vue-qrcode'
import util from '@/util/util'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    VueQrcode,
    DatePicker,
  },
  data() {
    return {
      dateOfBirth: null,
      name: '',
      email: '',
      phone: '',
      alert: '',
      loadingButton: true,
      member: null,
      yourpoint: 0,
      qrcode: '',
      objectId: '',
      isEditable: false,
      address: '',
      validateAlert: '',
      profile: JSON.parse(localStorage.getItem('profile')),
      qrModal: false,
      footer: '',
      editModal: false,
      close: false,
      loadingMessage: '',
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
    userId() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_LINE_USER_ID
      } else {
        if (this.profile) {
          return this.profile.userId || liff.getContext().userId
        } else {
          alert('มีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
          liff.closeWindow()
        }
      }
    },
    displayName() {
      if (this.profile) {
        return this.profile.displayName
      } else {
        return ''
      }
    },
    pictureUrl() {
      if (this.profile) {
        return this.profile.pictureUrl
      } else {
        return '/img/human.png'
      }
    },
  },
  created() {
    this.$store.dispatch('getShopLineOA')
    this.getUser()
  },
  methods: {
    generatedMemberQRCode() {
      return this.qrcode
      // return "deep=member#code=" + this.qrcode
    },
    cancel() {
      this.getUser()
      this.editModal = false
    },
    showDateOfBirth(dateOfBirth) {
      if (dateOfBirth) {
        return moment(dateOfBirth).format('LL')
      } else {
        return '-'
      }
    },
    showYourPoint(point) {
      return util.convertNumber(point)
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return EMAILREG.test(email)
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
    disabledRange: function (date) {
       return date > new Date();
    },
    validate() {
      if (this.name === '') {
        this.validateAlert = 'กรุณากรอกชื่อ-สกุล'
      } else if (this.phone === '') {
        this.validateAlert = 'กรุณากรอกเบอร์โทรฯ'
      } else {
        if (!this.validatePhone(this.phone)) {
          this.validateAlert = 'เบอร์โทรฯ​ ไม่ถูกต้อง'
        } else {
          this.validateAlert = ''
          return true
        }
      }
    },
    getUser() {
      const uid = this.uid
      this.loadingMessage = 'Loading user...'
      let userId = this.userId
      
      pos({
        method: 'get',
        url: '/api/v1.0/member/' + uid + '/getbyuserId/' + userId,
      }).then((response) => {
          if (response.data.error.code === 0) {
            const member = response.data.data.documents 
            this.member = member

            if (member !== null) {
              this.loadingMessage = ''

              this.yourpoint = member.point
              this.name = (member.firstname || member.name || '') + (member.lastname || '')
              this.phone = member.phone
              this.email = member.email
              const birthDate = moment(member.dateOfBirth)
              if (birthDate.isValid()) {
                this.dateOfBirth = birthDate
              }
              
              this.objectId = member.objectId
              this.address = member.address

              if (member.custCode !== undefined) {
                this.qrcode = member.custCode.toString()
              }
            }
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    updateMember() {
      let uid = this.uid
      let pictureUrl = this.pictureUrl
      let displayName = this.displayName
      let dateOfBirth = moment(this.dateOfBirth).format('YYYY-MM-DD')

      let data = {
        avatar: pictureUrl,
        displayName: displayName,
        objectId: this.objectId,
        name: this.name,
        phone: this.phone,
        email: this.email,
        dateOfBirth: dateOfBirth,
        address: this.address,
      }

      let validateData = this.validate()
      if (validateData === true && this.objectId !== '') {
        pos.post('/api/v1.0/member/' + uid + '/updatecrm', data)
        .then((res) => {
          this.getUser()
          this.editModal = false
        }).catch((error) => {
          console.log(error)
        })
      }
    },
  },
}
</script>
<style>
@media screen and (min-width: 200px) and (max-width: 767px) {
  .modal-edited {
    width: 85%;
    margin: auto;
    border-radius: 20px;
  }
}

input:required:invalid {
  border-color: rgb(239, 51, 108);
}
</style>
