<template>
  <CRow>
    <CCol md="12">
      <p class="font-weight-normal text-dark">ประวัติการแลกของรางวัล</p>
      <hr class="mb-1" />
      <h6 v-if="documents.length === 0" class="text-description" style="font-size:12px">
        ไม่มีข้อมูลการแลกของรางวัล
      </h6>
      <CListGroup class="list-group list-group-flush">
        <CListGroupItem
          class="pl-0 pr-0"
          v-for="card in documents"
          :key="card.objectId">
          <router-link
            :to="`/rewardcard/redemption/${card.objectId}`"
            style="text-decoration:none">
            <table style="width:100%" class="text-left" aria-describedby="">
              <tr>
                <td scope="col" style="width:50px">
                  <img :src="logo"
                      width="50px"
                      class="img-fluid p-2 rounded-circle"
                      @error="noImgUrl" v-if="logo !== ''"/>
                      
                      <img v-else src="../../../../../public/silom-pos.png" 
                      width="50px" 
                      class="img-fluid p-2 rounded-circle"
                      @error="noImgUrl" />
                </td>
                <td style="vertical-align:top;" >
                  <div class="text-dark my-auto">
                    รหัสแลกรับสินค้า {{ card.redemptionCode }}
                  </div>
                  <div class="text-description" style="font-size:12px">
                    วันที่ : {{ createdAt(card.created_at) }}
                  </div>
                </td>
                <td style="width:5%">
                  <label v-if="card.isUsed === true" style="font-size:12px">
                    <CBadge color="danger">
                      ใช้งานแล้ว
                    </CBadge>
                  </label>
                </td>
              </tr>
            </table>
          </router-link>
        </CListGroupItem>
      </CListGroup>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import moment from 'moment'
import liff from '@line/liff'

export default {
  props: ['rewardcardObjectId'],
  data() {
    return {
      documents: [],
      profile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    userId() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_LINE_USER_ID
      } else {
        if (this.profile) {
          return this.profile.userId || liff.getContext().userId
        } else {
          alert('มีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
          liff.closeWindow()
        }
      }
    },
    uid() {
      return this.$route.query.uid
    },
    shop() {
      return this.shopLineOA[0]
    },
    logo() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.remoteImagePath
      }
    },
  },
  created() {
    if (this.rewardcardObjectId != '') {
      this.getRewardCardRedemption()
    }
  },
  methods: {
    noImgUrl(event) {
      event.target.src =
        'https://cdn-icons-png.flaticon.com/512/1586/1586085.png'
    },
    createdAt(datetime) {
      return moment(datetime).format('LLL')
    },
    getRewardCardRedemption() {
      if (this.rewardcardObjectId === '') {
        return
      }
      
      const uid = this.uid
      const userId = this.userId

      crm
        .get(
          '/api/v1.0/' +
            uid +
            '/rewardcard/' +
            this.rewardcardObjectId +
            '/getredemption/' +
            userId
        )
        .then((response) => {
          this.documents = response.data.data.documents
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  watch: {
    rewardcardObjectId() {
      this.getRewardCardRedemption()
    },
  },
}
</script>
