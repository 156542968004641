<template>
  <CRow>
    <CCol md="12">
      <div v-if="myVoucher.length !== 0">
        <CJumbotron
          class="p-0 mb-3"
          v-for="voucher in myVoucher"
          :key="voucher.objectId"
          color="white"
          border-color="greyborder"
        >
          <router-link
            style="text-decoration:none"
            :to="`/voucher/redemption/${voucher.objectId}`">
            <table style="width:100%;" aria-describedby="">
              <tr>
                <th scope="col" style="width:30%" class="text-center">
                  <div
                    class="square-box"
                    :style="{
                      'background-image': `url('${voucher.Voucher.imgUrl}'), url('${noImgUrl}')`,
                      width: '100%',
                      'background-size': 'cover',
                      'background-position': 'center center',
                    }"
                  ></div>
                </th>
                <td class="pl-2" style="padding:8px;padding-bottom: 4px; vertical-align:top">
                  <h6 class="text-black font-weight-normal">
                    {{ voucher.Voucher.title }}
                  </h6>

                  <div class="d-flex justify-content-between pr-2">
                    <span class="text-description" style="font-size:12px">
                        หมดอายุ:
                      {{ expiredDate(voucher.endAt) }}
                    </span>

                    <CBadge
                      style="font-size:8px;float: right;"
                      color="danger"
                      class="p-1"
                      v-if="checkVoucherStatus(voucher.endAt, voucher.isUsed)"
                    >
                      {{ checkVoucherStatus(voucher.endAt, voucher.isUsed) }}
                    </CBadge>
                  </div>
                </td>
              </tr>
            </table>
          </router-link>
        </CJumbotron>
      </div>
      <div v-else class="text-center">
        <img
          alt=""
          src="../../../../../public/img/reward/voucher.png"
          class="img-fluid"
          width="50%"
        />
        <h4 class="text-dark">คุณไม่มีรายการ E-Voucher</h4>
        <p class="text-grey">สามารถซื้อ E-Voucher ได้จากร้านค้า</p>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import crm from '@/services/crm'
import liff from '@line/liff'
import moment from 'moment'

export default {
  data() {
    return {
      myVoucher: '',
      profile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    userId() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_LINE_USER_ID
      } else {
        if (this.profile) {
          return this.profile.userId || liff.getContext().userId
        } else {
          alert('มีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
          liff.closeWindow()
        }
      }
    },
    uid() {
      return this.$route.query.uid
    },
    noImgUrl() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getMyVoucher()
  },
  methods: {
    checkVoucherStatus(endAt, isUsed) {
      let datetime = new Date()
      let expiration = moment(endAt).isBefore(datetime)

      if (isUsed === true) {
        return 'ใช้งานแล้ว'
      } else {
        if (expiration) {
          return 'หมดอายุแล้ว'
        }
      }

      return null
    },
    expiredDate(date) {
      if (date === '') {
        return '-'
      } else {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    getMyVoucher() {
      let uid = this.uid
      let userId = this.userId

      crm
        .get('/api/v1.0/' + uid + '/voucher/getredemption/' + userId)
        .then((res) => {
          this.myVoucher = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
.square-box {
  position: relative;
  width: 20%;
  overflow: hidden;
  border-radius: 5px;
}
.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>
